import { Flex, Layout, Select, Spin } from "antd";
import { useEffect, useState } from "react";

import { Icon } from "icons";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { resetQuestionAnswer } from "redux-store/question-slice";
import {
  fetchSettings,
  updateSelectedSettings,
} from "redux-store/settings-slice";
import CustomModal from "shared/CustomModal";
import { SHOW_SETTINGS } from "utils/constants";

import NavMenu from "./NavMenu";

const { Header } = Layout;

type Props = {
  headerClass: string;
};

const NavHeader = ({ headerClass }: Props) => {
  const dispatch = useAppDispatch();
  const { isFetchingSettings, defaultSettings, selectedSettings } =
    useAppSelector((state) => state.settingsSlice);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState("");

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  const getDatabaseOptions = () =>
    defaultSettings?.graphVersions?.map((item) => ({
      value: item,
      label: item,
    }));

  const getSelectSuffixIcon = () =>
    isFetchingSettings === "loading" ? (
      <Spin size="small" />
    ) : (
      <Icon icon="CaretDown" />
    );

  const handleOnSelect = (value: string) => {
    setSelectedDatabase(value);
    setIsModalOpen(true);
  };

  const handleChangeDatabase = () => {
    dispatch(resetQuestionAnswer());
    dispatch(
      updateSelectedSettings({
        graphVersion: selectedDatabase,
        minScore: defaultSettings?.minScore,
        maxSourceCount: defaultSettings?.maxSourceCount,
      })
    );
    setIsModalOpen(false);
  };

  const databaseModalBody = () => (
    <>
      <p>Selecting a different graph will clear and reset this page. </p>
      <p> Do you confirm this change?</p>
    </>
  );

  return (
    <>
      <Header className={headerClass} data-testid="app-header">
        <Flex align="center" justify="space-between">
          <Flex className="left-section">
            <Icon icon="Logo" />
            <NavMenu />
          </Flex>
          {SHOW_SETTINGS && (
            <Select
              placeholder="Graph Database"
              options={getDatabaseOptions()}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              className="app-select db-select"
              dropdownAlign={{ offset: [0, 0] }}
              suffixIcon={getSelectSuffixIcon()}
              onChange={handleOnSelect}
              value={selectedSettings?.graphVersion}
            />
          )}
        </Flex>
      </Header>
      <CustomModal
        handlePrimaryBtnClick={handleChangeDatabase}
        handleSecondaryBtnClick={() => setIsModalOpen(false)}
        modalBody={databaseModalBody()}
        onCancel={() => setIsModalOpen(false)}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        visible={isModalOpen}
      />
    </>
  );
};

export default NavHeader;
