export const baseUrl = process.env.REACT_APP_GRAPH_RAG_URL;
export const SHOW_SETTINGS = false;

export const DEFAULT_ERROR_MSG =
  "Something went wrong, Please try again later.";

export enum NavItems {
  QuestionAnswer = "/question-answer",
  Logs = "/logs",
}

export const GENERIC_SIZE_OPTIONS = [10, 25, 50, 100];

export const DEFAULT_LOGS_PAGINATION = {
  pageSize: 10,
  pageIndex: 0,
};
