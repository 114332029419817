import { configureStore } from "@reduxjs/toolkit";

import logsSlice from "./logs-slice";
import questionSlice from "./question-slice";
import settingsSlice from "./settings-slice";

export const store = configureStore({
  reducer: {
    logsSlice,
    questionSlice,
    settingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
