import { ThemeConfig } from "antd";

import { lightColorPalette, lightPalette } from "./light-palette";

export const lightTheme: ThemeConfig = {
  token: {
    borderRadius: 0,
    fontFamily: "Oxygen",
    colorText: lightColorPalette.grey[900],
  },
  components: {
    Button: {
      borderRadius: 57,
      colorPrimary: lightColorPalette.purple[500],
      fontWeight: 700,
      fontSize: 14,
      colorPrimaryHover: lightColorPalette.purple[500],
      defaultActiveBorderColor: lightColorPalette.purple[500],
      defaultBorderColor: lightColorPalette.purple[500],
      defaultHoverColor: lightColorPalette.grey[900],
      colorLink: lightColorPalette.purple[500],
      colorLinkHover: lightColorPalette.purple[700],
      colorTextDisabled: lightColorPalette.grey[300],
    },
    Input: {
      borderRadius: 27,
      fontSize: 16,
      colorText: lightColorPalette.grey[900],
      colorBorder: "transparent",
    },
    InputNumber: {
      fontSize: 13,
      colorBorder: lightColorPalette.purple[400],
    },
    Card: {
      colorBorderSecondary: lightColorPalette.grey[100],
      borderRadiusLG: 9,
    },
    Collapse: {
      headerBg: lightColorPalette.white[500],
      colorBorder: lightColorPalette.grey[100],
      borderRadiusLG: 9,
    },
    Select: {
      borderRadius: 15,
      colorBorder: lightColorPalette.purple[400],
      colorText: lightColorPalette.grey[900],
      fontSize: 13,
    },
    Modal: {
      borderRadiusLG: 12,
      fontSize: 14,
    },
    Tag: {
      colorErrorBorder: lightColorPalette.red[400],
      colorError: lightColorPalette.red[400],
      colorSuccessBorder: lightColorPalette.green[500],
      colorSuccess: lightColorPalette.green[500],
      borderRadiusSM: 5,
    },
    Checkbox: {
      borderRadiusSM: 3,
    },
    DatePicker: {
      colorPrimary: lightColorPalette.purple[500],
      colorBorder: lightColorPalette.purple[400],
      hoverBorderColor: lightColorPalette.purple[500],
      cellActiveWithRangeBg: lightColorPalette.grey[50],
      borderRadius: 2,
      inputFontSize: 13,
    },
  },
};

const createCssVar = (items: any, prefix = "-"): string[] =>
  Object.entries(items).flatMap(([key, value]) => {
    const varName = `${prefix}-${key}`;
    if (typeof value === "object") return createCssVar(value, varName);
    return `${varName}:${value}`;
  });

const createCssVars = (themeColors: any) => createCssVar(themeColors).join(";");
const sheet = document.styleSheets[0];
sheet.insertRule(`:root{${createCssVars(lightPalette)}}`, 1);

export {};
