import { IAskRequest, IVerifyAnswerRequest } from "models/question.model";

import { axiosConfig } from "./axios";

export const askQuestionAPI = async (request: IAskRequest) => {
  const url = `answers/v1/ask`;
  const response = await axiosConfig("POST", url, { ...request });
  return response;
};

export const verifyAnswerAPI = async (request: IVerifyAnswerRequest) => {
  const url = `/answers/v1/verify`;
  const response = await axiosConfig("POST", url, { ...request });
  return response;
};
