import { LogsListingRequest } from "models/logs.model";

import { axiosConfig } from "./axios";

export const fetchLogsAPI = async (request: LogsListingRequest) => {
  const { graph, ...newSearchRequest } = request;
  const url = `logs/${graph}/search`;
  const response = await axiosConfig("POST", url, { ...newSearchRequest });
  return response;
};
