import "App.scss";

import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import BaseLayout from "layout/BaseLayout";

const QuestionAnswer = lazy(() => import("pages/QuestionAnswer"));
const Logs = lazy(() => import("pages/Logs"));

const App = () => (
  // TODO: Add ticket for spinner/loader design
  <Suspense fallback={<>Loading...</>}>
    <Routes>
      <Route path="/" element={<Navigate to="/question-answer" />} />
      <Route
        path="/question-answer"
        element={<BaseLayout component={<QuestionAnswer />} />}
      />
      <Route path="/logs" element={<BaseLayout component={<Logs />} />} />
    </Routes>
  </Suspense>
);
export default App;
