import "./BaseLayout.scss";

import { Layout } from "antd";
import { ReactNode } from "react";

import NavHeader from "./NavHeader";

type Props = {
  component: ReactNode;
};

const { Content } = Layout;

const BaseLayout = ({ component }: Props) => (
  <>
    <NavHeader headerClass="app-header" />
    <Content className="app-content-wrapper">{component}</Content>
  </>
);

export default BaseLayout;
