import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchLogsAPI } from "api/logs";
import { LogsListingRequest, LogsListingResponse } from "models/logs.model";
import { InterceptedResponse, Status } from "models/shared";

interface LogsState {
  isFetchingLogs: Status;
  logsResponse: LogsListingResponse;
}

const initialState = {
  isFetchingLogs: "idle",
} as LogsState;

export const fetchLogs = createAsyncThunk(
  "logs/fetchLogs",
  async (request: LogsListingRequest) => {
    const response = await fetchLogsAPI(request);
    return response;
  }
);

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLogs.pending, (state: LogsState) => {
        state.isFetchingLogs = "loading";
      })
      .addCase(fetchLogs.fulfilled, (state: LogsState, { payload }) => {
        state.isFetchingLogs = "succeeded";
        state.logsResponse = (payload as InterceptedResponse).data;
      })
      .addCase(fetchLogs.rejected, (state: LogsState) => {
        state.isFetchingLogs = "failed";
      });
  },
});

export default logsSlice.reducer;
