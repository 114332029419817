import "./CustomModal.scss";

import { Button, Flex, Modal } from "antd";

import { IModal } from "models/shared";

const CustomModal = (props: IModal) => {
  const {
    handlePrimaryBtnClick,
    handleSecondaryBtnClick,
    isPrimaryBtnLoading,
    modalBody,
    onCancel,
    primaryBtnText,
    secondaryBtnText,
    visible,
  } = props;

  const footer = () => (
    <Flex justify="space-between">
      <Button
        type="default"
        onClick={handleSecondaryBtnClick}
        className="app-default-btn"
      >
        {secondaryBtnText}
      </Button>

      <Button
        type="primary"
        onClick={handlePrimaryBtnClick}
        loading={isPrimaryBtnLoading}
        className="app-primary-btn"
      >
        {primaryBtnText}
      </Button>
    </Flex>
  );

  return (
    <Modal
      centered
      closable={false}
      destroyOnClose
      footer={footer}
      onCancel={onCancel}
      open={visible}
      zIndex={3}
      className="custom-modal-wrapper"
    >
      {modalBody}
    </Modal>
  );
};

export default CustomModal;
