import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchSettingsAPI } from "api/settings";
import { IDefaultSettings, ISettings } from "models/settings.model";
import { InterceptedResponse, Status } from "models/shared";

interface SettingsState {
  isFetchingSettings: Status;
  defaultSettings: IDefaultSettings;
  selectedSettings: ISettings;
}

const initialState = {
  isFetchingSettings: "idle",
  defaultSettings: {},
} as SettingsState;

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async () => {
    const response = await fetchSettingsAPI();
    return response;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetDefaultSettings: (state) => {
      const { minScore, maxSourceCount } = state.defaultSettings;
      state.selectedSettings = {
        ...state.selectedSettings,
        minScore,
        maxSourceCount,
      };
    },
    updateSelectedSettings: (state, action) => {
      state.selectedSettings = {
        ...state.selectedSettings,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSettings.pending, (state: SettingsState) => {
        state.isFetchingSettings = "loading";
      })
      .addCase(fetchSettings.fulfilled, (state: SettingsState, { payload }) => {
        state.isFetchingSettings = "succeeded";
        state.defaultSettings = (payload as InterceptedResponse).data;
        const { minScore, maxSourceCount, defaultGraphVersion } =
          state.defaultSettings;
        state.selectedSettings = {
          minScore,
          maxSourceCount,
          graphVersion: defaultGraphVersion,
        };
      })
      .addCase(fetchSettings.rejected, (state: SettingsState) => {
        state.isFetchingSettings = "failed";
      });
  },
});

export const { resetDefaultSettings, updateSelectedSettings } =
  settingsSlice.actions;

export default settingsSlice.reducer;
